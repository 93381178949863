import * as React from "react";
import Progress from "./Progress";
// images references in the manifest
import "../../../assets/connect-app-icon.png";

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface AppState {
  listItems: [];
}

export default class App extends React.Component<AppProps, AppState> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      listItems: []
    };
  }

  componentDidMount() {
    let dataTokens = localStorage.getItem("accessToken");

    //Also check if the token is expired or not
    if (dataTokens) {
      window.location.href = "/ecards.html?";
    } else {
      window.location.href = "signIn.html";
    }
  }

  render() {
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return <Progress title={title} logo="connect-app-icon.png" message="Please sideload addin." />;
    }

    return <div></div>;
  }
}
